import React from "react";

export function formatCurrency(nominal) {
  nominal = nominal ? nominal : 0;
  // nominal = nominal.replace(".", "");
  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(nominal);
  // return price.substring(0, price.length - 3);
  let value = price.split("p")[1];
  return value;
}

export function checkAndformatCurrency(str) {
  if (str.match(/^\d+(\.\d*)?$/)) {
    return parseInt(Number(str) * 100, 10) // convert the number to cents
      .toString() // convert back to string
      .replace(/(..)$/, ".$1") // add dot, e.g. restore currency from cents
      .replace(/(.)(.{12})$/, "$1,$2") // add comma to billion
      .replace(/(.)(.{9})$/, "$1,$2") // add comma to million
      .replace(/(.)(.{6})$/, "$1,$2"); // add comma to thousand
  } else {
    return ""; // return empty string if format is incorrect
  }
}

export function formatCurrencyWithoutSymbol(nominal) {
  nominal = nominal ? nominal : 0;
  // const formattedPrice = Math.floor(nominal).toLocaleString("id-ID");
  const price = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(nominal);
  let formattedPrice = price.split("p")[1];

  return formattedPrice;
}

export function FormatCurrencies(initCurrency, nominal) {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="mr-1">
        <span>{initCurrency}</span>
      </div>
      <div className="ml-1">
        <span>{formatCurrencyWithoutSymbol(nominal)}</span>
      </div>
    </div>
  );
}
