import React from "react";
import {
  // NavItem,
  // NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  // Media,
  // Badge,
  Row,
  Col,
} from "reactstrap";
// import PerfectScrollbar from "react-perfect-scrollbar";
import axios from "axios";
import * as Icon from "react-feather";
// import classnames from "classnames";
// import Autocomplete from "../../../components/@vuexy/autoComplete/AutoCompleteComponent";
import { history } from "../../../history";
import { logout } from "../../../api/controller/AuthController";
import { formatCurrencyWithoutSymbol } from "../../../utility/formatCurrency";
import { ArrowDownBlack } from "../../../assets/img";

const UserDropdown = (props) => {
  const handleLogout = async () => {
    let log = await logout();

    if (log) {
      history.push("/");
    }
  };
  return (
    <DropdownMenu right>
      <DropdownItem tag="a" href="#">
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle">Edit Profile</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Mail size={14} className="mr-50" />
        <span className="align-middle">My Inbox</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.CheckSquare size={14} className="mr-50" />
        <span className="align-middle">Tasks</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.MessageSquare size={14} className="mr-50" />
        <span className="align-middle">Chats</span>
      </DropdownItem>
      <DropdownItem tag="a" href="#">
        <Icon.Heart size={14} className="mr-50" />
        <span className="align-middle">WishList</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem tag="a" href="" onClick={() => handleLogout()}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right mr-md-5">
        {/* <NavItem className="nav-search" onClick={this.handleNavbarSearch}>
          <NavLink className="nav-link-search">
            <Icon.Search size={21} data-tour="search" />
          </NavLink>
          <div
            className={classnames("search-input", {
              open: this.state.navbarSearch,
              "d-none": this.state.navbarSearch === false,
            })}
          >
            <div className="search-input-icon">
              <Icon.Search size={17} className="primary" />
            </div>
            <Autocomplete
              className="form-control"
              suggestions={this.state.suggestions}
              filterKey="title"
              filterHeaderKey="groupTitle"
              grouped={true}
              placeholder="Explore Vuexy..."
              autoFocus={true}
              clearInput={this.state.navbarSearch}
              externalClick={(e) => {
                this.setState({ navbarSearch: false });
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 27 || e.keyCode === 13) {
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay("");
                }
              }}
              customRender={(
                item,
                i,
                filteredData,
                activeSuggestion,
                onSuggestionItemClick,
                onSuggestionItemHover
              ) => {
                const IconTag = Icon[item.icon ? item.icon : "X"];
                return (
                  <li
                    className={classnames("suggestion-item", {
                      active: filteredData.indexOf(item) === activeSuggestion,
                    })}
                    key={i}
                    onClick={(e) => onSuggestionItemClick(item.link, e)}
                    onMouseEnter={() =>
                      onSuggestionItemHover(filteredData.indexOf(item))
                    }
                  >
                    <div
                      className={classnames({
                        "d-flex justify-content-between align-items-center":
                          item.file || item.img,
                      })}
                    >
                      <div className="item-container d-flex">
                        {item.icon ? (
                          <IconTag size={17} />
                        ) : item.file ? (
                          <img
                            src={item.file}
                            height="36"
                            width="28"
                            alt={item.title}
                          />
                        ) : item.img ? (
                          <img
                            className="rounded-circle mt-25"
                            src={item.img}
                            height="28"
                            width="28"
                            alt={item.title}
                          />
                        ) : null}
                        <div className="item-info ml-1">
                          <p className="align-middle mb-0">{item.title}</p>
                          {item.by || item.email ? (
                            <small className="text-muted">
                              {item.by
                                ? item.by
                                : item.email
                                ? item.email
                                : null}
                            </small>
                          ) : null}
                        </div>
                      </div>
                      {item.size || item.date ? (
                        <div className="meta-container">
                          <small className="text-muted">
                            {item.size
                              ? item.size
                              : item.date
                              ? item.date
                              : null}
                          </small>
                        </div>
                      ) : null}
                    </div>
                  </li>
                );
              }}
              onSuggestionsShown={(userInput) => {
                if (this.state.navbarSearch) {
                  this.props.handleAppOverlay(userInput);
                }
              }}
            />
            <div className="search-input-close">
              <Icon.X
                size={24}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({
                    navbarSearch: false,
                  });
                  this.props.handleAppOverlay("");
                }}
              />
            </div>
          </div>
        </NavItem> */}
        <UncontrolledDropdown
          tag="li"
          className="dropdown-notification nav-item mr-3"
        >
          <DropdownToggle tag="a" className="nav-link nav-link-label">
            <div className="d-flex">
              <div style={{ marginTop: "5px", marginRight: "5px" }}>
                <span>{formatCurrencyWithoutSymbol(153450990)} IDR</span>
              </div>
              <div>
                <img
                  src={ArrowDownBlack}
                  alt="arrow-down"
                  width={16}
                  height={16}
                />
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu
            tag="ul"
            right
            className="dropdown-menu-media p-1"
            style={{ borderRadius: "5px" }}
          >
            <li
              className="dropdown-menu-header"
              style={{ borderRadius: "5px" }}
            >
              <div
                className="dropdown-header mt-0"
                style={{ marginBottom: "5px" }}
              >
                <p
                  className="notification-title"
                  style={{ marginBottom: "10px" }}
                >
                  Credit Available
                </p>
                <span
                  className="text-white text-bold-600"
                  style={{ fontSize: "1.41rem" }}
                >
                  {formatCurrencyWithoutSymbol(153450990)} IDR
                </span>
              </div>
            </li>
            <li className="dropdown-menu-body">
              <Row className="border-bottom">
                <Col
                  xs="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="border-right my-1"
                >
                  <div className="px-1">
                    <p style={{ marginBottom: "7px" }}>Credit Available</p>
                    <span
                      className="text-bold-600"
                      style={{ fontSize: "1rem" }}
                    >
                      {formatCurrencyWithoutSymbol(153450990)} IDR
                    </span>
                  </div>
                </Col>
                <Col xs="6" sm="6" md="6" lg="6" xl="6" className="my-1">
                  <div className="text-right px-1">
                    <p style={{ marginBottom: "7px" }}>Used</p>
                    <span
                      className="text-bold-600"
                      style={{ fontSize: "1rem" }}
                    >
                      {formatCurrencyWithoutSymbol(53450990)} IDR
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs="6"
                  sm="6"
                  md="6"
                  lg="6"
                  xl="6"
                  className="border-right my-1"
                >
                  <div className="px-1">
                    <p style={{ marginBottom: "7px" }}>Credit Available</p>
                    <span
                      className="text-bold-600"
                      style={{ fontSize: "1rem" }}
                    >
                      {formatCurrencyWithoutSymbol(153450990)} IDR
                    </span>
                  </div>
                </Col>
                <Col xs="6" sm="6" md="6" lg="6" xl="6" className="my-1">
                  <div className="text-right px-1">
                    <p style={{ marginBottom: "7px" }}>Used</p>
                    <span
                      className="text-bold-600"
                      style={{ fontSize: "1rem" }}
                    >
                      {formatCurrencyWithoutSymbol(43450990)} IDR
                    </span>
                  </div>
                </Col>
              </Row>
            </li>
          </DropdownMenu>
        </UncontrolledDropdown>
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <span data-tour="user" className="user-nav">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
              <span className="user-status">{this.props.userRole}</span>
            </div>
            <span>
              <img
                src={ArrowDownBlack}
                alt="arrow-down"
                width={16}
                height={16}
                style={{ boxShadow: "none" }}
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
export default NavbarUser;
