import secureLocalStorage from "react-secure-storage";
import { postLogin } from "../config/Method";
import { AsyncStorage } from "reactjs-async-localstorage";

export const login = (data) => {
  return postLogin("/login", data)
    .then(function (res) {
      return res;
    })
    .catch(function (err) {
      return err;
    });
};
export const logout = async () => {
  await AsyncStorage.clear();
  secureLocalStorage.clear();
  return true;
};
