export const colors = {
  black: "#464646",
  green: "#209fae",
  menu: "#164E55",
  red: "#E5696E",
  purple: "#9F4DB6",
  menu_light: "#2B6A72",
  menu_text_off: "#A8A8A8",
  menu_text_on: "#FFFFFF",
  white: "#FFFFFF",
  secondary: "#d4d4d4",
  blue: "#1B68FF",
  grey: "#f6f6f6",
  mantis: "#2D720C",
  borders: "#D8D6DE",
  border_pagination: "#DFDFDF",
  bg_pagination: "#F9F9F9",
  group_header: "#C8C8C8",
  bg_thead: "#F3F2F7",
  border: "#D8D6DE",
  emerald: "#32A020",
  tea: "#A9E9C5",
  orange: "#FFF3E8",
  greenStat: "#12774F",
  sidebar_text: "#4B4B73",
  bg_th: "#E5ECF6",
  danger_input: "#ea5455",
  border_input: "#D9D9D9",
};
