import React, { useContext } from "react";
import secureLocalStorage from "react-secure-storage";

const { createContext, useState, useEffect, useMemo } = require("react");

const UserContext = createContext();

export const UserProvider = (props) => {
  const { children } = props;
  const [loadingUser, setLoadingUser] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [token, setToken] = useState(null);
  const [menu, setMenu] = useState([]);

  const fetchData = async () => {
    try {
      const info = secureLocalStorage.getItem("user");
      const role = secureLocalStorage.getItem("role");
      const menu = secureLocalStorage.getItem("menu");
      const token = secureLocalStorage.getItem("token");

      if (info) {
        setUserInfo(JSON.parse(info));
      }
      if (role) {
        setUserRole(JSON.parse(role));
      }
      if (menu) {
        const menus = JSON.parse(menu);
        setMenu(menus.sort((a, b) => a.order - b.order));
      }
      if (token) {
        setToken(token);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const valueContext = useMemo(
    () => ({
      action: {
        setUserInfo,
        setUserRole,
        setMenu,
        setToken,
      },
      state: {
        userInfo,
        userRole,
        menu,
        token,
        loadingUser,
      },
    }),
    [loadingUser, menu, token, userInfo, userRole]
  );

  return (
    <UserContext.Provider value={valueContext}>{children}</UserContext.Provider>
  );
};

export const UserData = () => useContext(UserContext);
