import React, { createContext, useContext, useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";

const IDContext = createContext();

export const IDProvider = ({ children }) => {
  const [ID, setID] = useState("");

  const SetID = async (value) => {
    secureLocalStorage.setItem("ID", value);
    setID(value);
  };

  const GetID = async () => {
    const id = secureLocalStorage.getItem("ID");

    if (id) {
      setID(id);
    }
  };

  useEffect(() => {
    GetID();
  }, []);

  return (
    <IDContext.Provider value={{ ID, SetID }}>{children}</IDContext.Provider>
  );
};

export const useID = () => useContext(IDContext);
