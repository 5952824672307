import Axios from "axios";
import { BASE_URL } from "./Config";
import secureLocalStorage from "react-secure-storage";

export const postLogin = async (url, data) => {
  return await Axios.post(BASE_URL + url, data, {
    headers: { Accept: "application/json" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postMe = async (url) => {
  let token = secureLocalStorage.getItem("token");
  return await Axios.post(BASE_URL + url, {
    headers: { Authorization: "Bearer " + token, Accept: "application/json" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const get = async (url, data) => {
  let token = secureLocalStorage.getItem("token");
  return await Axios.get(BASE_URL + url + data, {
    headers: { Authorization: "Bearer " + token, Accept: "application/json" },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getDataByParams = async (url, data) => {
  let token = secureLocalStorage.getItem("token");
  return await Axios.get(BASE_URL + url, {
    headers: { Authorization: "Bearer " + token, Accept: "application/json" },
    params: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const post = async (url, data, data1, file, file1, deleted) => {
  let token = secureLocalStorage.getItem("token");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }

  if (data1) {
    formData.set("data1", JSON.stringify(data1));
  }

  if (file) {
    formData.append("file", file, file.name);
  }

  if (file1) {
    for (let i in file1) {
      formData.append(`file1[${i}]`, file1[i], file1[i].name);
    }
  }

  if (deleted) {
    formData.set("deleted", JSON.stringify(deleted));
  }
  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const postDataIcon = async (url, data, data1, icon, iconActive) => {
  let token = secureLocalStorage.getItem("token");
  let formData = new FormData();

  if (data) {
    for (let x in data) {
      formData.set(x, data[x]);
    }
  }

  if (data1) {
    formData.set("data1", JSON.stringify(data1));
  }

  if (icon) {
    formData.append("icon", icon, icon.name);
  }

  if (iconActive) {
    formData.append("iconActive", iconActive, iconActive.name);
  }

  return await Axios.post(BASE_URL + url, formData, {
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
