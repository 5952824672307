export const BackgrounLogin = require("./svg/login-booking.svg");
export const EyeBlack = require("./svg/eye-black.svg");
export const EyeBlackOutline = require("./svg/eye-black-outline.svg");
export const LogoFuntravia = require("./svg/logo-funtravia.svg");
export const DefaultMenu = require("./svg/default-menu.svg");
export const LogoFontFuntravia = require("./svg/logo-f-funtravia.svg");
export const LogoFontFuntraviaPNG = require("./png/logo-font-funtravia.png");
export const CancelBlack = require("./svg/cancel-black.svg");
export const CancelWhite = require("./svg/fi-rr-cross-small.svg");
export const ChecklistWhite = require("./svg/checklist-white.svg");
export const EditPurple = require("./svg/edit-purple.svg");
export const DeleteRed = require("./svg/delete-red.svg");
export const UploadBlack = require("./svg/upload-black.svg");
export const UserWhite = require("./svg/user-white.svg");
export const EditWhite = require("./svg/edit-white.svg");
export const DeleteWhite = require("./svg/delete-white.svg");
export const DocumentWhite = require("./svg/document-white.svg");
export const PreferenceBlack = require("./svg/preference-black.svg");
export const PurchaseRecordBlack = require("./svg/purchase-record-black.svg");
export const LimitWhite = require("./svg/limit-white.svg");
export const PrintWhite = require("./svg/print-white.svg");
export const HomeBlack = require("./svg/home-black.svg");
export const PlaneWhite = require("./svg/plane-white.svg");
export const PlaneGreen = require("./svg/plane-green.svg");
export const ChecklistBoldWhite = require("./svg/checklist-bold-white.svg");
export const UserWhite1 = require("./svg/user-white-1.svg");
export const MoneyWhite = require("./svg/money-white.svg");
export const ArrowDownBlack = require("./svg/arrow-down-black.svg");
export const ArrowUpBlack = require("./svg/arrow-up-black.svg");
export const EmailBlack = require("./svg/email-black.svg");
export const BarVertical = require("./svg/bar-vertical.svg");
export const CancelSquareBlack = require("./svg/cancel-square-black.svg");
export const PlusGreen = require("./svg/plus-green.svg");
export const DeleteGreen = require("./svg/delete-green.svg");
export const PhoneWhite = require("./svg/phone-white.svg");
export const ReloadWhite = require("./svg/reload-black.svg");
export const TicketWhite = require("./svg/ticket-white.svg");
export const HotelWhite = require("./svg/hotel-white.svg");
export const HotelGreen = require("./svg/hotel-green.svg");
export const NoteWhite = require("./svg/note-white.svg");
export const PlaneOutlineWhite = require("./svg/plane-outline-white.svg");
export const PlaneOutlineBlack = require("./svg/plane-outline-black.svg");
export const ClipWhite = require("./svg/clip-white.svg");
export const ArrowDownWhite = require("./svg/arrow-down-white.svg");
export const ArrowUpWhite = require("./svg/arrow-up-white.svg");
export const ProjectGreen = require("./svg/fi-sr-layers.svg");
export const HistogramOrange = require("./svg/fi-sr-chart-histogram.svg");
export const CreditCardGreen = require("./svg/fi-sr-credit-card.svg");
export const CreditCardWhiteOutline = require("./svg/fi-rr-credit-card-white-outline.svg");
export const DiamondWhite = require("./svg/fi-rr-diamond.svg");
export const CalendarBlackOutline = require("./svg/fi-rr-calendar.svg");
export const CalendarBlack = require("./svg/fi-sr-calendar.svg");
export const CheckListBlack = require("./svg/fi-rr-check (1).svg");

export const Bronze = require("./svg/Group-2802-bronze.svg");
export const Silver = require("./svg/Group-2806-silver.svg");
export const Gold = require("./svg/Group-2804-gold.svg");
export const Platinum = require("./svg/Group-26897-platinum.svg");
export const Diamond = require("./svg/Group-2808-diamond.svg");

// Image
export const defaultImage = require("./images/Group 27127.svg");
export const defaultImageLandscape = require("./images/Group 27128.svg");
export const UnderConstruction = require("./svg/under-construction.svg");
export const Icon = require("./svg/fi-rr-duplicate-black.svg");
export const IconActive = require("./svg/fi-rr-duplicate-white.svg");

// Format File
export const pdf = require("./png/pdf.png");
export const pdfWhite = require("./png/pdf-white.png");
export const doc = require("./png/doc.png");
export const jpg = require("./png/jpg.png");
export const xls = require("./png/xls.png");
export const EditGreen = require("./svg/edit-green.svg");

// * Main is color "#065F6A"
export const IconSearchMain = require("./svg/basic/icon-search-main.svg");
export const IconSearchWhite = require("./svg/basic/icon-search-white.svg");
export const IconRefreshMain = require("./svg/basic/icon-refresh-main.svg");
export const IconRefreshSubmain = require("./svg/basic/icon-refresh-submain.svg");
export const IconPlaneMain = require("./svg/basic/icon-plane-main.svg");
export const IconPlaneSubmain = require("./svg/basic/icon-plane-submain.svg");
export const IconPlaneWhite = require("./svg/basic/icon-plane-white.svg");
export const IconPlaneBlack = require("./svg/basic/icon-plane-black.svg");
export const IconPlaneGray = require("./svg/basic/icon-plane-gray.svg");
export const IconApartmentMain = require("./svg/basic/icon-apartment-main.svg");
export const IconApartmentSubmain = require("./svg/basic/icon-apartment-submain.svg");
export const IconApartmentWhite = require("./svg/basic/icon-apartment-white.svg");
export const IconApartmentBlack = require("./svg/basic/icon-apartment-black.svg");
export const IconApartmentGray = require("./svg/basic/icon-apartment-gray.svg");
export const IconSuitcaseMain = require("./svg/basic/icon-suitcase-main.svg");
export const IconSuitcaseSubmain = require("./svg/basic/icon-suitcase-submain.svg");
export const IconSuitcaseWhite = require("./svg/basic/icon-suitcase-white.svg");
export const IconSuitcaseBlack = require("./svg/basic/icon-suitcase-black.svg");
export const IconSuitcaseGray = require("./svg/basic/icon-suitcase-gray.svg");
export const IconCarMain = require("./svg/basic/icon-car-main.svg");
export const IconPassportMain = require("./svg/basic/icon-passport-main.svg");
export const IconVisaMain = require("./svg/basic/icon-visa-main.svg");
export const IconCargoMain = require("./svg/basic/icon-cargo-main.svg");
export const IconEyeBlack = require("./svg/basic/icon-eye-black.svg");
export const IconEditBlack = require("./svg/basic/icon-edit-black.svg");
export const IconEditBlue = require("./svg/basic/icon-edit-blue.svg");
export const IconEditWhite = require("./svg/edit-white.svg");
export const IconTrashBlack = require("./svg/basic/icon-trash-black.svg");
export const IconTrashWhite = require("./svg/basic/icon-trash-white.svg");
export const IconTrashRed = require("./svg/basic/icon-trash-red.svg");
export const IconTrashRedBlur = require("./svg/basic/icon-trash-red-blur.svg");
export const IconDocumentWhite = require("./svg/basic/icon-document-white.svg");
export const IconBuildingWhite = require("./svg/basic/icon-building-white.svg");
export const IconCalendarWhite = require("./svg/basic/icon-calendar-white.svg");
export const IconBudgetWhite = require("./svg/basic/icon-budget-white.svg");
export const IconUserWhite = require("./svg/basic/icon-user-white.svg");
export const IconClockOrange = require("./svg/basic/icon-clock-orange.svg");
export const IconExclamationRed = require("./svg/basic/icon-exclamation-red.svg");
export const IconRegPaperGreen = require("./svg/basic/icon-registration-paper-green.svg");
export const IconPlusMain = require("./svg/basic/icon-plus-main.svg");
export const IconPlusWhite = require("./svg/basic/icon-plus-white.svg");
export const IconPlusBlue = require("./svg/basic/icon-plus-blue.svg");
export const IconPlusGray = require("./svg/basic/icon-plus-gray.svg");
export const IconCrossBlack = require("./svg/basic/icon-cross-black.svg");
export const IconCrossWhite = require("./svg/basic/icon-cross-white.svg");
export const IconCrossRed = require("./svg/basic/icon-cross-red.svg");
export const IconEyeCrossedBlack = require("./svg/basic/icon-eye-crossed-black.svg");
export const IconSettingSubmain = require("./svg/basic/icon-setting-submain.svg");
export const IconSettingBlue = require("./svg/setting-blue.svg");
export const IconChecklistWhite = require("./svg/checklist-white.svg");

// ! Empty State
export const IconEmptyBackground = require("./svg/basic/empty-state/icon-empty-background.svg");
export const IconEmptyServiceSO = require("./svg/basic/empty-state/icon-empty-service-so.svg");
