import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AsyncStorage } from "reactjs-async-localstorage";

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [nextNo, setNextNo] = useState("");
  const [method, setMethod] = useState("");

  const SetNextNo = (value) => {
    AsyncStorage.setItem("NEXT_NO", JSON.stringify(value));
    setNextNo(value);
  };

  const SetMethod = (value) => {
    AsyncStorage.setItem("METHOD", value);
    setMethod(value);
  };

  const GetNextNo = async () => {
    const nextNo = await AsyncStorage.getItem("NEXT_NO");
    if (nextNo) {
      setNextNo(JSON.parse(nextNo));
    }
  };

  const GetMethod = async () => {
    const method = await AsyncStorage.getItem("METHOD");
    if (method) {
      setMethod(method);
    }
  };

  useEffect(() => {
    GetNextNo();
    GetMethod();
  }, []);

  const valueContext = useMemo(
    () => ({
      action: { SetNextNo, SetMethod },
      state: { nextNo, method },
    }),
    [nextNo, method]
  );

  return (
    <StateContext.Provider value={valueContext}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateGlobal = () => useContext(StateContext);
